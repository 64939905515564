<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center">
          <ion-img
            style="height: 85px;width: 170px;"
            src="assets/icon/AAP_Logo_original_para-mail.png"
          ></ion-img>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-text>Gestión de campañas agrícolas</ion-text>
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-align-items-center"
          style="margin-top: 24px"
        >
          <ion-col class="ion-align-self-center" size-md="6" size="12">
            <ion-card>
              <ion-card-header>
                <ion-card-title size="4">Recuperar Contraseña</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <form @submit.prevent="sendEmailForgetPassword">
                  <ion-item>
                    <ion-label position="floating">
                      Email
                    </ion-label>
                    <ion-input
                      :value="email"
                      inputmode="email"
                      type="email"
                      autocomplete="email"
                      placeholder="juanperez@hotmail.com"
                      required
                      @input="email = $event.target.value"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <a
                      slot="end"
                      style="cursor: pointer"
                      @click="$router.push('login')"
                    >
                      Volver a Iniciar Sesión.
                    </a>
                  </ion-item>
                  <ion-item v-show="error">
                    <ion-text color="danger">
                      <h4>{{ error }}</h4>
                    </ion-text>
                  </ion-item>
                  <ion-button
                    fill="outline"
                    class="login-btn"
                    color="medium"
                    @click="$router.push('/login')"
                  >
                    Cancelar
                  </ion-button>
                  <ion-button
                    slot="end"
                    class="ion-float-right login-btn"
                    type="submit"
                  >
                    Aceptar
                  </ion-button>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import isEmail from "validator/lib/isEmail";
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonPage,
  IonRow,
  IonCard,
  IonImg,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  alertController
} from "@ionic/vue";
export default {
  components: {
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonPage,
    IonRow,
    IonImg,
    IonCard,
    IonCol,
    IonButton,
    IonItem,
    IonInput,
    IonLabel
  },
  data: () => ({
    email: "",
    error: ""
  }),

  computed: {
    ...mapGetters(["isLoggedIn"])
  },

  methods: {
    ...mapActions(["forgetPassword"]),
    async showModalSendEmail() {
      const alert = await alertController.create({
        header: "Recuperación de contraseña",
        message: `Hemos enviado un email a su dirección de correo electrónico para continuar con la recuperación de contraseña. `,
        buttons: [
          {
            text: "Aceptar",
            handler: () => this.$router.push("/login")
          }
        ]
      });
      return alert.present();
    },

    async sendEmailForgetPassword() {
      this.error = "";
      if (!this.email || !isEmail(this.email)) {
        return (this.error = "Indícanos el email de la cuenta.");
      }
      this.loading = true;
      const { error } = await this.forgetPassword({ email: this.email });
      if (error) {
        this.error = error;
      } else {
        await this.showModalSendEmail();
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  #headerAAG {
    margin-left: 296px;
    margin-right: 296px;
    margin-top: 32px;
  }
  #tittle {
    margin-left: 104px;
  }
}
</style>
